export class UpdateEmailValidator {
	constructor(forms, id) {
		const self = this;
		self.id = id;
		self.target = false;
		self.valid = true;

		forms.on("input", function () {
			self.updateTarget(this);

			if (!self.valid && self.validateEmail()) {
				self.warn(false);
			}
			self.configureBtns();
		});

		forms.on("submit", function (e) {
			self.updateTarget(this);
			if (!self.validateEmail()) {
				e.preventDefault();
			}
			self.warn(!self.valid);
			self.configureBtns();
		});

		console.log("UpdateEmailValidator initialized", this);
	}

	updateTarget(target) {
		if (target !== this.target) {
			this.target = target;
			this.form = jQuery(target);
			this.oldEmail = this.form.find("input[name='old_email']").val();
			this.newEmail = this.form.find("input[name='new_email']");
			this.submit = this.form.find(`button.${this.id}__btn--update[type='submit']`);
			this.email = this.form.find(`button.${this.id}__btn--email[type='submit']`);
		}
	}

	validateEmail() {
		const regex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.valid = regex.test(String(this.newEmail.val()).toLowerCase());
		return this.valid;
	}

	warn(warn) {
		warn
			? this.form.addClass(`${this.id}__form--error`)
			: this.form.removeClass(`${this.id}__form--error`);
	}

	configureBtns() {
		const sameEmail = this.oldEmail === this.newEmail.val();
		this.disableBtn("submit", !this.valid || sameEmail);
		this.disableBtn("email", !sameEmail);
	}

	disableBtn(btn, disable) {
		disable ? this[btn].attr("disabled", true) : this[btn].removeAttr("disabled");
	}
}
