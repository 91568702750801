import {FormValidator, StateManager} from "../_classes";

export function initLoginRegister() {
	// Regsiter Validation:
	new FormValidator(
		"register",
		{
			billing_first_name: ["hasValue"],
			billing_last_name: ["hasValue"],
			username: [
				"hasValue",
				"noEmail",
				"noSpaces",
				"isLowercaseAlphaNumeric",
				"has4Letters",
				"maxLength30"
			],
			email: ["hasValue", "isEmail"],
			password: [
				"hasValue",
				"minLength8",
				"hasCapitalLetter",
				"hasLowercaseLetter",
				"hasNumber",
				"hasSpecialCharacter"
			],
			retype_password: ["hasValue", "matchesDependant"]
		},
		{
			retype_password: "password"
		}
	);

	// Login Validation:
	new FormValidator("login", {
		username: ["hasValue", "noSpaces"],
		password: ["hasValue"]
	});

	// Initialize the StateManager for login/register functionality:
	const logRegManager = new StateManager({
		name: "access",
		states: ["register", "login"],
		hashControl: true
	});

	// ensure the link to login in error message on register form runs JS to change the state to login:
	$(document.body).on("click", ".sf-message a.showlogin", function (e) {
		e.preventDefault();
		logRegManager.change("login");
	});
}
