export function initSubscriptionsManagement() {
	const $cancelSubscriptionContainer = $("#sfwc-cancel-subscription");
	const $cancelSubscriptionBtn = $("[data-cancel-subscription]");
	if ($cancelSubscriptionContainer.length && $cancelSubscriptionBtn.length) {
		$cancelSubscriptionBtn.click(function (e) {
			$cancelSubscriptionContainer.toggleClass("sfwc-cancel-subscription--confirm");
		});
	}

	const $updateSubscriptionEmailContainer = $("#sfwc-change-subscription-email");
	const $updateSubscriptionEmailBtn = $("#sfwc-change-subscription-email__btn");
	if ($updateSubscriptionEmailContainer.length && $updateSubscriptionEmailBtn.length) {
		$updateSubscriptionEmailBtn.click(function (e) {
			$updateSubscriptionEmailContainer.toggleClass("sfwc-change-subscription-email--update");
		});
	}
}
