import {UpdateEmailValidator} from "../_classes/UpdateEmailValidator";
import {initEditAccount} from "./initEditAccount";
import {matchLocationPath} from "../sf-utils";
import {initResetPassword} from "./initResetPassword";
import {initSubscriptionsManagement} from "./initSubscriptionsManagement";

export function initAccount() {
	if (matchLocationPath(["/my-account"], false)) {
		const assignedUserForms = $("form.assign-user__form"); // get assigned user forms...
		if (assignedUserForms.length) {
			new UpdateEmailValidator(assignedUserForms, "assign-user"); // ...if found, initialize with the UpdateEmailValidator
		}

		const subscriptionEmailForms = $("form.sfwc-change-subscription-email__form"); // get subscription email forms...
		if (subscriptionEmailForms.length) {
			new UpdateEmailValidator(subscriptionEmailForms, "sfwc-change-subscription-email"); // ...if found, initialize with the UpdateEmailValidator
		}

		initEditAccount();
		initResetPassword();
		initSubscriptionsManagement();

		// EDIT ADDRESS VALIDATION:
		// SF TOOD: Add validation for edit address form
		// new FormValidator(
		// 	"register",
		// 	{
		// 		billing_first_name: ["hasValue"],
		// 		billing_last_name: ["hasValue"],
		// 		username: [
		// 			"hasValue",
		// 			"noEmail",
		// 			"noSpaces",
		// 			"isLowercaseAlphaNumeric",
		// 			"has4Letters",
		// 			"maxLength30"
		// 		],
		// 		email: ["hasValue", "isEmail"]
		// 	},
		// 	true
		// );
	}
}
